import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle/PageTitle'
import ContentBlock from '../components/LandingContentBlock/ContentBlock'
import SEO from '../components/SEO/SEO'
import './scss/one-on-one.scss'



export default () => (
    <Layout>
        <SEO
            title='Maths Tutor Christchurch, NZ | Zest 4 Maths | Private Maths Tuition | Zest 4 Maths'
            description='Our one on one tuition can be face to face at our studio in Lincoln, or online tuition from the
comfort of your home, choose what suits you best.'/>
        <PageTitle title="One on One">
            One-on-one tuition in a friendly, relaxed environment
        </PageTitle>
        <div className='blocks-container'>
        <ContentBlock>
            <div className="divider-container">
                <div className="divider-container__item">
                    <h2>Face to Face</h2>
                    <p>Face-to-Face tuition takes place at our maths studio in Lincoln. We work together on a whiteboard and our work is captured via an ‘online classroom’. 
                        Your tutor takes photos of everything that has been produced either in your workbook or on the whiteboard, and this is uploaded to your online classroom.</p>
                </div>
                <div className="divider-container__item">
                    <div className="divider-container__item__img-container">
                    <img src={'/Zest4Maths-maths-student-whiteboard.jpg'} />
                    </div>
                
                </div>
            </div>
        </ContentBlock>
        <ContentBlock>
            <div className="divider-container">
            <div className="divider-container__item">
                    <div className="divider-container__item__img-container">
                    <img src={'/Zest4Maths-maths-student-online.jpg'} />
                    </div>
                
                </div>
                <div className="divider-container__item">
                    <h2>Online Tuition</h2>
                    <h3>Login and receive personalised tuition in the comfort of your own home</h3>
                    <p>Just like the Face-to-Face tuition, your tutor captures everything that has been worked on during the session. This can be accessed via the online classroom, with your own unique link, on a computer or tablet/iPad.</p>
                    <p>When we’re working together, we use an online whiteboard to write on so both you and your tutor solve problems together. It’s just like working side by side with your tutor, but with the convenience of learning from home</p>
                    <p>The online tuition is a great way to have personalised one-on-one help if you can’t travel, and will suit you wherever your location.</p>
                </div>
            </div>
        </ContentBlock>
        <ContentBlock>
            <div className="divider-container">

                <div className="divider-container__item">
                    <h2>BitPaper</h2>
                    <h3>Zest 4 Maths uses BitPaper to make online tuition a breeze</h3>
                    <p>BitPaper is a collaborative online whiteboard used by teachers and students worldwide. It creates an online environment for a teacher and student to solve problems together as if they were working side by side on a classroom whiteboard.</p>
                    <p>The ability to receive top class maths tuition from the comfort of your own home is something which sets Zest 4 Maths apart from its competitors. It is easy to use and time productive - a great option for anyone looking for help up and down the country.</p>
                    <p>Even some local students who could come and have Face-to-Face maths tuition choose to have online tuition for the convenience it offers them.</p>
                </div>
                <div className="divider-container__item">
                    <div className="divider-container__item__img-container">
                    <img src={'/bitpaper.png'} />
                    </div>
                
                </div>
            </div>
        </ContentBlock>
        </div>
       
    </Layout>
)